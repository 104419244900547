import { ManualPatchPayload, ManualPatchType, PatchAvailableHourSlot, PatchDriverPayload } from 'interfaces';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { onPublish as manualPatchesOnPublish } from 'slices/manualPatches';
import { ResponsePayload } from 'slices/subscriptive';
import { createSingleSubscriptiveSlice } from 'slices/subscriptive/single';
import { Socket } from 'socket.io-client';
import { RootState } from 'store/reducer';
import { emitAsync } from 'utils/socket';
import { v4 } from 'uuid';
import { setError } from './errors';

const { select, unsubscribe, reducer, onPublish, subscribe, selectResource, slice } = createSingleSubscriptiveSlice({
  name: 'manualPatch',
  reducers: {},
  volatile: false,
});

const { setLoading } = slice.actions;

export default slice.reducer;

export class CreateManualPatchPayload {
  clientResourceId: string;
  from?: string | null;
  to?: string | null;
  companyId?: string | null;
  driverId?: string | null;
  timezone?: string | null;
  shiftAmount?: number | null;
  type: ManualPatchType;
  driverName?: string | null;
  drivers?: PatchDriverPayload[];
}

export class UpdateManualPatchPayload extends CreateManualPatchPayload {
  id: string;
}

export class ManualPatchActionPayload {
  id: string;
  version: number;
}

const remove =
  (id: string, version: number) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload<null | undefined>>(socket, `manualPatch:finish-shift`, {
      id,
      version,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

export const startRollback =
  (id: string, version: number, restart: boolean) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload<null | undefined>>(socket, `manualPatch:start-rollback`, {
      id,
      version,
      restart,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

export const stopRollback =
  (id: string, version: number) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload<null | undefined>>(socket, `manualPatch:stop-rollback`, {
      id,
      version,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

const start =
  (id: string, version: number, shiftAmount: number, type: ManualPatchType, fixCertifications: boolean) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload<null | undefined>>(socket, `manualPatch:start-shift`, {
      id,
      version,
      shiftAmount,
      type,
      fixCertifications,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

const stop =
  (id: string, version: number) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload<null | undefined>>(socket, `manualPatch:stop-shift`, {
      id,
      version,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

const create =
  (patch: CreateManualPatchPayload) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<{ id: string; drivers?: PatchDriverPayload[] }>> => {
    const account = getState().myAccount.resource;
    const socket = getSocket();
    if (!!account) {
      const patchesCount = Object.keys(getState().manualPatches.resourceDictionary).length;

      const now = new Date();
      if (patchesCount > 0) {
        const optimisticPatch: ManualPatchPayload = {
          ownerId: account.id,
          ...patch,
          version: 0,
          status: 'created',
          id: v4(),
          createdAt: now.toISOString(),
          updatedAt: now.toISOString(),
          loading: true,
          deletedAt: null,
          shiftAmount: 1,
          drivers: [],
        };
        // await dispatch(onPublish(optimisticPatch));
        // await dispatch(manualPatchesOnPublish([optimisticPatch]));
        const response = await emitAsync<ResponsePayload<{ id: string; drivers?: PatchDriverPayload[] }>>(
          socket,
          `manualPatch:create`,
          patch
        );
        if (response.status !== 'ok') {
          const now = new Date();
          const deletedPatch = {
            ...optimisticPatch,
            deletedAt: now.toISOString(),
          };
          dispatch(setError({ status: response.status, msg: response.msg }));
          // dispatch(onPublish(deletedPatch));
          // dispatch(manualPatchesOnPublish([deletedPatch]));
        }
        return response;
      } else {
        const response = await emitAsync<ResponsePayload<{ id: string; drivers?: PatchDriverPayload[] }>>(
          socket,
          `manualPatch:create`,
          patch
        );

        if (response.status !== 'ok') {
          dispatch(setError({ status: response.status, msg: response.msg }));
        }

        return response;
      }
    }

    const error = {
      status: 'error',
      msg: 'Synchronization error',
    };

    dispatch(setError(error));
    return error;
  };

const update =
  (patch: UpdateManualPatchPayload, existingPatch: ManualPatchPayload) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();
    if (!!existingPatch) {
      const optimisticPatch: ManualPatchPayload = {
        ...existingPatch,
        ...patch,
        version: existingPatch.version,
        loading: true,
        shiftAmount: 1,
      };
      await dispatch(onPublish(optimisticPatch));
      await dispatch(manualPatchesOnPublish([optimisticPatch]));

      const response = await emitAsync<ResponsePayload>(socket, `manualPatch:update`, patch, false);
      if (response.status !== 'ok') {
        dispatch(onPublish(existingPatch));
        dispatch(manualPatchesOnPublish([existingPatch]));
        dispatch(setError({ status: response.status, msg: response.msg }));
      }
      return response;
    }

    const error = {
      status: 'error',
      msg: 'Synchronization error',
    };

    dispatch(setError(error));
    return error;
  };

export class UpdateManualPatchDriverPayload {
  patchId: string;
  driverId: string;
  updatedDriverId?: string;
  from?: string | null;
  to?: string | null;
}

const updateDriver =
  (driver: UpdateManualPatchDriverPayload) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();
    dispatch(setLoading(true));

    const response = await emitAsync<ResponsePayload>(socket, 'manualPatch:update-driver', driver, false);

    dispatch(setLoading(false));

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

export class AddManualPatchDriverPayload {
  patchId: string;
  driverId: string;
  from?: string | null;
  to?: string | null;
}
const addDriver =
  (driver: AddManualPatchDriverPayload) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();
    dispatch(setLoading(true));

    const response = await emitAsync<ResponsePayload>(socket, 'manualPatch:add-driver', driver, false);

    dispatch(setLoading(false));

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

export class RemoveManualPatchDriverPayload {
  patchId: string;
  driverId: string;
}
const removeDriver =
  (driver: RemoveManualPatchDriverPayload) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();
    dispatch(setLoading(true));

    const response = await emitAsync<ResponsePayload>(socket, 'manualPatch:remove-driver', driver, false);

    dispatch(setLoading(false));

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

const fetch =
  (id: string, version: number, drivers?: string[]) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload>(socket, `manualPatch:fetch`, {
      id,
      version,
      drivers,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

const removeUnidentifiedEvents =
  (id: string, version: number) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<ResponsePayload<null | undefined>> => {
    const socket = getSocket();
    dispatch(setLoading(true));

    const response = await emitAsync<ResponsePayload<null | undefined>>(
      socket,
      'manualPatch:delete-unidentified',
      {
        id,
        version,
      },
      false
    );

    dispatch(setLoading(false));

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

const recalculateEngineHours =
  (id: string) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();

    const response = await emitAsync<ResponsePayload>(socket, 'manualPatch:recalculate-engine-hours', {
      id,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

const generateIntermediateEvents =
  (id: string) =>
  async (dispatch: Dispatch<any>, getState: () => RootState, getSocket: () => Socket): Promise<ResponsePayload> => {
    const socket = getSocket();
    dispatch(setLoading(true));

    const response = await emitAsync<ResponsePayload>(
      socket,
      'manualPatch:generate-intermediate-events',
      {
        id,
      },
      false
    );

    dispatch(setLoading(false));

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response;
  };

export {
  unsubscribe,
  reducer,
  onPublish,
  subscribe,
  slice,
  fetch,
  update,
  updateDriver,
  addDriver,
  removeDriver,
  create,
  stop,
  start,
  remove,
  removeUnidentifiedEvents,
  recalculateEngineHours,
  generateIntermediateEvents,
};

export const useManualPatch = () => {
  const { loading, subscribed, resource } = useSelector(select);

  return {
    patch: resource as ManualPatchPayload | undefined,
    patchLoading: loading,
    patchSubscribed: subscribed,
  };
};

export const getAvailableHours =
  (id: string) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<PatchAvailableHourSlot[]> => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload<PatchAvailableHourSlot[]>>(
      socket,
      'manualPatch:get-available-hours',
      {
        manualPatchId: id,
      }
    );

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response.data;
  };
