import { DriverPayload, DriverStatus } from "interfaces";
import * as r from 'ramda';
import { useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { createRelativeSubscriptiveSlice, RelativeSubscriptiveResourceList } from 'slices/subscriptive/relative';
import { Socket } from 'socket.io-client';
import { RootState } from '../store/reducer';
import { ResponsePayload } from './subscriptive';
import { emitAsync } from "../utils/socket";
import { setError } from "./errors";

export type DriversRootState = {
  [k: string]: RelativeSubscriptiveResourceList<DriverPayload>;
};

const { select, unsubscribe, reducer, reconnect, onPublish, subscribe, selectChildResourceList, slice } =
  createRelativeSubscriptiveSlice({
    name: 'drivers',
    // @ts-expect-error _id does not exist
    idProp: '_id',
    payloadType: DriverPayload,
    parentName: 'companies',
    parentSingleName: 'company',
    deletedFilterFn(resource): boolean {
      return resource.deletedAt != null;
    },
    reducers: {},
  });

export default slice.reducer;

export const useDrivers = (accountId: string | null) => {
  const {
    resourceDictionary: driversById,
    loading: driversLoading,
    subscribed: driversSubscribed,
  } = useSelector(r.partial(select, [accountId])) || {};

  let drivers = useSelector(r.partial(selectChildResourceList, [accountId]));
  let onlyDriversById = driversById;

  // TODO: return only drivers from backend
  if (drivers instanceof Array && drivers.length > 0) {
    onlyDriversById = r.indexBy(
      r.prop('_id'),
      Object.values(driversById as object).filter((driver) => driver.role.id === 'DRIVER')
    );
    drivers = (drivers as DriverPayload[]).filter((driver) => driver.role.id === 'DRIVER');
  }

  return {
    drivers: drivers as DriverPayload[] | undefined,
    driversById: onlyDriversById as Record<string, DriverPayload> | undefined,
    driversLoading,
    driversSubscribed,
  };
};

const getDriverStatus =
  (id: string, companyId: string) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => RootState,
    getSocket: () => Socket
  ): Promise<DriverStatus> => {
    const socket = getSocket();
    const response = await emitAsync<ResponsePayload<DriverStatus>>(socket, `driver/status:get`, {
      id,
      companyId,
    });

    if (response.status !== 'ok') {
      dispatch(setError({ status: response.status, msg: response.msg }));
    }

    return response.data;
  };

export { unsubscribe, reducer, reconnect, onPublish, subscribe, getDriverStatus };
