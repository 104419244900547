import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Row, Select, Switch, Tooltip } from 'antd';
import { useAuthConnectionEffect } from 'hooks/useAuthConnectionEffect';
import { ManualPatchPayload, PatchDriverPayload } from 'interfaces';
import moment from 'moment';
import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as CompaniesSlice from 'slices/companies';
import { useCompanies } from 'slices/companies';
import { useDrivers } from 'slices/drivers';
import {
  RemoveManualPatchDriverPayload,
  UpdateManualPatchDriverPayload,
  UpdateManualPatchPayload,
} from 'slices/manualPatch';
import { ResponsePayload } from 'slices/subscriptive';
import { fuzzySelectFilter, getContainer } from 'utils/html';
import { timezones } from '../utils/timezone';

export default function SelectStep(props: {
  onlyLast8Days: boolean;
  patch: ManualPatchPayload;
  updatePatch: (patch: UpdateManualPatchPayload, reset?: boolean) => Promise<ResponsePayload>;
  updatePatchDriver: (driver: UpdateManualPatchDriverPayload) => Promise<ResponsePayload>;
  removePatchDriver: (driver: RemoveManualPatchDriverPayload) => Promise<ResponsePayload>;
  disabled: boolean | undefined;
  fetchEvents: (driverId?: string) => void;
  onAvailableHrsClick: () => void;
}) {
  const dispatch = useDispatch();
  const { updatePatch, updatePatchDriver, removePatchDriver, patch, disabled, fetchEvents, onAvailableHrsClick } =
    props;
  const { companies, companiesSubscribed, companiesLoading } = useCompanies();
  const { drivers, driversSubscribed, driversLoading } = useDrivers(patch.companyId || null);
  const [
    mainDriverDateRange,
    setMainDriverDateRange,
  ] = useState<{ from: string | null; to: string | null }>({
    from: patch?.from || moment.utc().subtract(9, 'days').toISOString(),
    to: patch?.to || moment.utc().toISOString(),
  });

  useAuthConnectionEffect(() => {
    dispatch(CompaniesSlice.subscribe());
    return () => {
      dispatch(CompaniesSlice.unsubscribe());
    };
  }, []);

  const patchDrivers = useMemo(() => {
    return [...patch.drivers].sort((a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf());
  }, [patch]);

  const [
    hasCoDriver,
    setHasCoDriver,
  ] = useState(() => patchDrivers.length > 1);

  const driverLogUrls = useMemo(() => {
    const hosPortalUrl = process.env.REACT_APP_HOS_PORTAL_URL;
    if (
      !hosPortalUrl ||
      companies.length === 0 ||
      !drivers ||
      drivers?.length === 0 ||
      !patch.companyId ||
      patch.drivers.length === 0
    ) {
      return {} as Record<string, string>;
    }

    const selectedCompany = companies.find((company) => company._id === patch.companyId);
    if (selectedCompany === undefined) {
      return {} as Record<string, string>;
    }

    return patch.drivers.reduce(
      (acc, patchDriver) => {
        const driverHomeTerminalId = drivers.find((driver) => driver._id === patchDriver.driverId)?.driverInfo
          ?.homeTerminal.id;
        const driverTimezone =
          driverHomeTerminalId !== undefined
            ? selectedCompany.terminals.filter((terminal) => terminal.id === driverHomeTerminalId)[0].timeZone.id
            : selectedCompany.timeZone.id;

        acc[patchDriver.driverId] = `${hosPortalUrl}/portal/log/${patchDriver.driverId}/${moment
          .tz(timezones[driverTimezone])
          .format('M-D-YYYY')}/0/${driverTimezone}`;
        return acc;
      },
      {} as Record<string, string>
    );
  }, [
    patch.companyId,
    patch.drivers,
    drivers,
    companies,
  ]);

  const eightDaysAgo = moment().subtract(8, 'days');

  const driverSelectorRow = (key: string, patchDriver?: PatchDriverPayload, isFirstRow = false) =>
    isFirstRow || (!isFirstRow && hasCoDriver) ? (
      <Row
        key={key}
        justify="start"
        gutter={[
          8,
          8,
        ]}
        style={{ marginBottom: '8px' }}
      >
        {isFirstRow ? (
          <>
            <Col span={2} style={{ display: 'inline-flex', alignItems: 'center' }}>
              <Switch
                checkedChildren="- CoDriver"
                unCheckedChildren="+ CoDriver"
                checked={hasCoDriver}
                onChange={(checked) => {
                  if (!checked && patchDrivers.length > 1) {
                    const coDriver = patchDrivers[1];
                    // noinspection JSIgnoredPromiseFromCall
                    removePatchDriver({
                      patchId: coDriver.patchId,
                      driverId: coDriver.driverId,
                    });
                  }
                  setHasCoDriver(checked);
                }}
              />
            </Col>
            <Col span={5}>
              <Tooltip
                mouseEnterDelay={1}
                getPopupContainer={getContainer}
                getTooltipContainer={getContainer}
                title="Select company"
              >
                <Select<string, { label: string; value: string }>
                  placeholder="Select company"
                  showSearch
                  getPopupContainer={getContainer}
                  loading={companiesLoading}
                  disabled={disabled || !companiesSubscribed}
                  options={companies.map((company) => ({
                    label: company.name,
                    value: company._id,
                  }))}
                  value={patch.companyId || undefined}
                  onChange={(companyId) => {
                    companyId !== patch.companyId &&
                      updatePatch(
                        R.pipe<ManualPatchPayload, ManualPatchPayload, ManualPatchPayload>(
                          R.assoc('companyId', companyId),
                          R.assoc('driverId', null)
                        )(patch)
                      );
                  }}
                  filterOption={fuzzySelectFilter}
                  style={{ display: 'block' }}
                />
              </Tooltip>
            </Col>
          </>
        ) : null}
        <Col span={6} offset={!isFirstRow ? 7 : 0}>
          <Tooltip
            mouseEnterDelay={1}
            getPopupContainer={getContainer}
            getTooltipContainer={getContainer}
            title="Select driver"
          >
            <Select<string | undefined, { label: string; value: string }>
              placeholder="Select driver"
              showSearch
              getPopupContainer={getContainer}
              loading={driversLoading}
              disabled={
                disabled ||
                !driversSubscribed ||
                (patchDriver?.driverId !== undefined &&
                  drivers?.find((driver) => driver._id === patchDriver?.driverId) === undefined)
              }
              allowClear={patchDriver && !isFirstRow}
              options={drivers
                ?.filter(
                  (driver) =>
                    driver._id === patchDriver?.driverId ||
                    !patch.drivers.map((patchDriver) => patchDriver.driverId).includes(driver._id)
                )
                .map((driver) => ({
                  label: `${driver.firstName} ${driver.lastName}`,
                  value: driver._id,
                }))}
              value={patchDriver?.driverId}
              onChange={(driverId) => {
                if (!driverId) {
                  return;
                }

                if (patchDriver?.driverId === undefined) {
                  // noinspection JSIgnoredPromiseFromCall
                  updatePatch({
                    ...patch,
                    driverId,
                    from: mainDriverDateRange.from,
                    to: mainDriverDateRange.to,
                  });
                } else if (patchDriver.driverId !== driverId) {
                  // noinspection JSIgnoredPromiseFromCall
                  updatePatchDriver({
                    ...patchDriver,
                    updatedDriverId: driverId,
                    from: mainDriverDateRange.from,
                    to: mainDriverDateRange.to,
                  });
                }
              }}
              onClear={() => {
                if (patchDriver) {
                  // noinspection JSIgnoredPromiseFromCall
                  removePatchDriver({
                    patchId: patchDriver.patchId,
                    driverId: patchDriver.driverId,
                  });
                }
              }}
              filterOption={fuzzySelectFilter}
              style={{ display: 'block' }}
            />
          </Tooltip>
        </Col>
        <Col span={7}>
          <DatePicker.RangePicker
            ranges={{
              'Last 9 days': [
                moment().subtract(9, 'days'),
                moment(),
              ],
              'Last 16 days': [
                moment().subtract(16, 'days'),
                moment(),
              ],
              'Last 30 days': [
                moment().subtract(30, 'days'),
                moment(),
              ],
            }}
            disabledDate={(date) => (props.onlyLast8Days ? eightDaysAgo.isAfter(date) : false)}
            disabled={disabled || !patchDriver}
            allowClear={false}
            value={[
              patchDriver?.from ? moment.utc(patchDriver.from) : null,
              patchDriver?.to ? moment.utc(patchDriver.to) : null,
            ]}
            format="MMM DD, YYYY"
            onChange={(range) => {
              if (range) {
                if (isFirstRow) {
                  let from = null;
                  let to = null;
                  if (range[0]) {
                    from = moment.utc(range[0].format('YYYY-MM-DD')).toISOString();
                  }
                  if (range[1]) {
                    to = moment.utc(range[1].format('YYYY-MM-DD'))?.toISOString();
                  }
                  setMainDriverDateRange({
                    from,
                    to,
                  });
                }
                if (patchDriver) {
                  // noinspection JSIgnoredPromiseFromCall
                  updatePatchDriver({
                    ...patchDriver,
                    from: range[0] ? moment.utc(range[0].format('YYYY-MM-DD'))?.toISOString() : null,
                    to: range[1] ? moment.utc(range[1].format('YYYY-MM-DD'))?.toISOString() : null,
                  });
                } else {
                  // noinspection JSIgnoredPromiseFromCall
                  updatePatch({
                    ...patch,
                    from: range[0] ? moment.utc(range[0].format('YYYY-MM-DD'))?.toISOString() : null,
                    to: range[1] ? moment.utc(range[1].format('YYYY-MM-DD'))?.toISOString() : null,
                  });
                }
              }
            }}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={3}>
          <Button
            disabled={disabled || !(patchDriver?.driverId && patchDriver?.from && patchDriver?.to)}
            type="primary"
            htmlType="submit"
            onClick={() => fetchEvents(patchDriver?.driverId)}
            style={{ width: '100%' }}
          >
            LOAD
          </Button>
        </Col>
        <Col span={1}>
          {patchDriver ? (
            <Tooltip title={`Please log in to HOS portal of ${patch.companyName} before using`}>
              <a
                href={driverLogUrls[patchDriver.driverId]}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  paddingTop: 0,
                  lineHeight: '30px',
                }}
                title="Go to driver's log"
              >
                <InfoCircleOutlined />
              </a>
            </Tooltip>
          ) : null}
        </Col>
      </Row>
    ) : null;

  return (
    <Row>
      <Col span={21}>
        {[
          ...patchDrivers,
          ...[undefined].filter((val) => (patchDrivers.length === 2 ? val !== undefined : true)),
        ].map((driver, index) => driverSelectorRow(`driver-${index}`, driver, index === 0))}
      </Col>
      <Col span={3}>
        <Button type="dashed" onClick={onAvailableHrsClick}>
          Available Hours
        </Button>
      </Col>
    </Row>
  );
}
